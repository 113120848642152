//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    serverHour: 0,
    serverMinute: 0,
    serverTimeString: "",
    serverTimeShortString: "",
    refTerminalHour: 0,
    refTerminalMinute: 0,
    actTerminalHour: 0,
    strTerminalTime: "--:--",
    isRunning: false,
    timer: null,
    updateTimeCount: 0,
    gpsLoc: "",
  }),
  created() {
    this.getGPSLoc();
  },
  mounted() {
    this.start();
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    start() {
      this.isRunning = true;
      this.updateTimeCount = 600 + 1;
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.updateTime();
        }, 1000);
      }
    },
    stop() {
      this.isRunning = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.updateTimeCount = 0;
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
    },
    getGPSLoc() {
      if (!("geolocation" in navigator)) {
        return;
      }

      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.gpsLoc = pos.coords.latitude + ", " + pos.coords.longitude;
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
    updateTime() {
      if (
        this.refTerminalHour > this.actTerminalHour ||
        this.updateTimeCount > 600 ||
        this.strTerminalTime > "23:59"
      ) {
        this.getServerTime();
      }
      setTimeout(() => {
        this.getstrHHMM();
        this.updateTimeCount++;
      }, 200);
    },
    getstrHHMM() {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      const today = new Date();
      this.actTerminalHour = today.getHours();
      this.actTerminalMinute = today.getMinutes();

      this.strTerminalTime =
        zeroPad(
          this.serverHour + (this.actTerminalHour - this.refTerminalHour),
          2
        ) +
        ":" +
        zeroPad(
          this.serverMinute + (today.getMinutes() - this.refTerminalMinute),
          2
        );
      //console.log(this.strTerminalTime); //TODO: TREURE....
    },
    setTerminalTime() {
      const today = new Date();
      this.refTerminalHour = today.getHours();
      this.refTerminalMinute = today.getMinutes();
      this.actTerminalHour = today.getHours();
      this.actTerminalMinute = today.getMinutes();
    },
    getServerTime() {
      axios
        .post(api.URL() + "/api/v1/getservertime", {
          token: sessionStorage.getItem("Token"),
          language: "ca",
        })
        .then((response) => {
          if (response.error == null) {
            this.updateTimeCount = 0;
            this.serverHour = parseInt(response.data.serverDateHour);
            this.serverMinute = parseInt(response.data.serverDateMinute);
            this.serverTimeString = response.data.serverTimeString;
            this.serverTimeShortString = response.data.serverTimeShortString;
            this.setTerminalTime();
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    putCheckInOut() {
      var self = this;
      axios
        .post(api.URL() + "/api/v1/gcheckinout_append", {
          token: sessionStorage.getItem("Token"),
          biometricId: "1",
          userId: parseInt(sessionStorage.getItem("UserId")),
          checkType: "0",
          gpsLoc: this.gpsLoc,
        })
        .then(() => {
            self.goMenu();
        })
        .catch(function (error) {
          self.msgAlert(error.response.data, true);
        });
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close==true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
